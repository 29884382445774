import {useEffect, useState} from 'react'
import {useTranslation} from 'next-i18next'
import Image from 'next/image'

import {faLocationPin, faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import {components, OptionProps} from 'react-select'
import noResultsImage from '../../../public/images/map-no-results-image.svg'
import classNames from 'classnames'
import {ServiceDeliveryPointDetailsType} from './ServicePointDetail/ServicePointDetail'
import React from 'react'
import {SearchInput} from './ServicePointLocator'

type ServicePointSearchProps = {
  useWideMargin: boolean
  userPosition?: google.maps.LatLng
  didStartSearch: (input: SearchInput) => void
  searchInput: SearchInput
  setSearchInput: React.Dispatch<React.SetStateAction<SearchInput>>
  // setDisplayList: React.Dispatch<React.SetStateAction<boolean | null>>
  displayListMobileView: boolean | null
  deliveryPointDetails: ServiceDeliveryPointDetailsType | null
}

function ServicePointSearch({
  useWideMargin,
  didStartSearch,
  searchInput,
  userPosition,
  setSearchInput,
  // setDisplayList,
  displayListMobileView,
  deliveryPointDetails,
}: ServicePointSearchProps) {
  const {t} = useTranslation()

  const searchPlaceholder = t('label_service_point_locator_search_placeholder')

  const [inputString, setInputString] = useState(searchInput?.label ?? '')
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    if (searchInput) {
      setInputString(searchInput?.label)
    } else {
      setInputString('')
    }
  }, [searchInput])

  const Option = (props: OptionProps<{label: string; value: any}>) => {
    return (
      <div
        className="relative flex items-center lg:border lg:border-t-dats-s9"
        {...props}
      >
        <FontAwesomeIcon
          icon={faLocationPin}
          style={{color: '#005E75'}}
          size="xs"
          className="absolute h-5 pl-4"
        />
        <components.Option {...props} />
      </div>
    )
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <div className="mt-4 flex flex-col items-center justify-center">
          <Image
            src={noResultsImage}
            alt="no results"
            className="w-100 mb-4 block"
          />
          <p>{t('label_service_point_locator_search_no_results')}</p>
        </div>
      </components.NoOptionsMessage>
    )
  }

  const Menu = (props: any) => {
    setIsFocused(true)

    return (
      <>
        <components.Menu
          {...props}
          className={`${
            displayListMobileView ? 'border' : 'border-none'
          } shadow-md shadow-black/50 ${
            props.selectProps.inputValue.length === 0 ? 'lg:hidden' : ''
          }`}
        />
      </>
    )
  }

  const inputWrapperClass = classNames(
    'flex h-[47px] w-full items-center rounded-lg border-[#EBEDF5] bg-white relative font-body',
    {
      'border-none lg:shadow-md': isFocused,
      'border shadow-black/5 ': !isFocused && !displayListMobileView,
    },
  )

  const rootClass = classNames('absolute top-4 z-30 xl:w-auto xl:px-0', {
    'w-full  px-4': !displayListMobileView,
    'xl:right-36': useWideMargin,
    'xl:right-20': !useWideMargin,
    'w-[75%] right-6  z-40': displayListMobileView && !deliveryPointDetails,
  })

  const magnifierIcon = classNames('absolute z-30 h-auto pl-4 text-dats-s3', {
    'hidden lg:block': isFocused || displayListMobileView,
  })

  return (
    <>
      <div className={rootClass}>
        <div className={inputWrapperClass}>
          {!displayListMobileView ? (
            isFocused ? (
              <>
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  size="1x"
                  className="absolute z-30 h-5 cursor-pointer pl-4 text-dats-s3 lg:hidden"
                  onClick={() => setIsFocused(false)}
                />
              </>
            ) : null
          ) : null}

          <div className={magnifierIcon}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M11.1581 5.6789C11.1581 6.88031 10.7686 7.9959 10.1151 8.90026L13.5477 12.3328C13.8844 12.6695 13.8844 13.2108 13.5477 13.5475C13.211 13.8841 12.6698 13.8841 12.3331 13.5475L8.9005 10.1149C7.99614 10.775 6.88055 11.1578 5.67914 11.1578C2.65582 11.1578 0.200195 8.70222 0.200195 5.6789C0.200195 2.65558 2.65582 0.199951 5.67914 0.199951C8.70247 0.199951 11.1581 2.65558 11.1581 5.6789ZM1.91649 5.6789C1.91649 3.59954 3.59978 1.91625 5.67914 1.91625C7.7585 1.91625 9.44179 3.59954 9.44179 5.6789C9.44179 7.75826 7.7585 9.44155 5.67914 9.44155C3.59978 9.44155 1.91649 7.75826 1.91649 5.6789Z"
                fill="#949191"
              />
            </svg>
          </div>

          <GooglePlacesAutocomplete
            apiKey={process.env.NEXT_PUBLIC_GOOGLEMAPS_API_KEY}
            autocompletionRequest={{
              radius: 500,
              location: {
                lat: userPosition?.lat() || 50.7338156,
                lng: userPosition?.lng() || 4.2069115,
              },
            }}
            selectProps={{
              onInputChange: value => {
                if (
                  (value && value.length > 0) ||
                  (value.length === 0 && inputString.length > 0)
                ) {
                  setInputString(value)
                }
              },
              maxMenuHeight: 500,
              isClearable: true,
              value: searchInput,
              inputValue: inputString,
              onChange: newValue => {
                didStartSearch(newValue)
              },
              onBlur: () => {
                setIsFocused(false)
                // Re-enable if search input must be cleared right after searching.
                /* setIsFocused(false), setInputString(''), setSearchInput(null)*/
              },
              className: `w-full h-full xl:w-80 ${
                displayListMobileView ? 'border' : 'shadow-dats border-none'
              } rounded-md font-body`,
              placeholder: !isFocused
                ? searchPlaceholder
                : inputString
                  ? inputString
                  : null,
              components: {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                NoOptionsMessage: NoOptionsMessage,
                Option: Option,
                Menu: Menu,
                LoadingIndicator: () => null,
                SingleValue: () => null,
              },
              styles: {
                control: baseStyles => ({
                  ...baseStyles,
                  border: 'none',
                  height: '100%',
                  FontFace: 'var(--font-opensans)',
                  width: '100%',
                  paddingLeft: displayListMobileView ? ' 10px' : '40px',
                  paddingRight: '40px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow:
                      '0 0 0 1px rgb(0 181 229 / var(--tw-text-opacity));',
                  },
                }),
                option: (baseStyles, state) => ({
                  ...baseStyles,
                  paddingLeft: '45px',
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  fontSize: '12px',
                  fontWeight: '600',
                  FontFace: 'var(--font-opensans)',
                  color: '#3B4344',
                  backgroundColor: state.isSelected
                    ? 'rgb(	222, 242, 166, 0.5)'
                    : state.isFocused
                      ? 'rgb(0, 181, 229,0.5)'
                      : '#fff',
                }),
                placeholder: baseStyles => ({
                  ...baseStyles,
                  fontSize: '14px',
                  fontFamily: 'var(--font-opensans)',
                  fontWeight: '600',
                  color: '#949191',
                }),
                valueContainer: baseStyles => ({
                  ...baseStyles,
                  fontFamily: 'var(--font-opensans)',
                  padding: 0,
                  // paddingLeft: displayListMobileView ? '10px' : '40px',
                }),
                menu: baseStyles => ({
                  ...baseStyles,
                  boxShadow: 'none',
                  margin: '1px',
                }),
                input: baseStyles => ({
                  ...baseStyles,
                  fontSize: '12px',
                  opacity: '1',
                  margin: 0,
                }),
              },
            }}
          />
          {searchInput || inputString ? (
            <div className="absolute right-0 cursor-pointer rounded-full lg:right-1.5">
              <div
                className="relative w-full"
                onClick={() => (setSearchInput(null), setInputString(''))}
              >
                <svg width="34" height="35" fill="none">
                  <path
                    d="M17.0002 24.3C18.8037 24.3 20.5333 23.5835 21.8085 22.3083C23.0838 21.033 23.8002 19.3034 23.8002 17.5C23.8002 15.6965 23.0838 13.9669 21.8085 12.6916C20.5333 11.4164 18.8037 10.7 17.0002 10.7C15.1967 10.7 13.4671 11.4164 12.1919 12.6916C10.9166 13.9669 10.2002 15.6965 10.2002 17.5C10.2002 19.3034 10.9166 21.033 12.1919 22.3083C13.4671 23.5835 15.1967 24.3 17.0002 24.3ZM14.8486 15.3484C15.0983 15.0987 15.5021 15.0987 15.7491 15.3484L16.9975 16.5968L18.246 15.3484C18.4957 15.0987 18.8994 15.0987 19.1464 15.3484C19.3935 15.5981 19.3961 16.0018 19.1464 16.2489L17.898 17.4973L19.1464 18.7457C19.3961 18.9954 19.3961 19.3992 19.1464 19.6462C18.8968 19.8932 18.493 19.8959 18.246 19.6462L16.9975 18.3978L15.7491 19.6462C15.4994 19.8959 15.0957 19.8959 14.8486 19.6462C14.6016 19.3965 14.5989 18.9928 14.8486 18.7457L16.0971 17.4973L14.8486 16.2489C14.5989 15.9992 14.5989 15.5954 14.8486 15.3484Z"
                    fill="#005E75"
                  />
                </svg>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {isFocused && !searchInput ? (
        <div className="absolute left-0 top-0 z-20 size-full border border-dats-border-gray bg-white p-4 shadow-dats lg:hidden"></div>
      ) : null}
    </>
  )
}

export default ServicePointSearch
