import React, {useEffect, useState} from 'react'
import {
  ChargingConnectorType,
  ServiceDeliveryPoint,
  ServiceDeliveryPointAvailability,
  ServiceDeliveryPointOperator,
  ServiceDeliveryPointSearchResponse,
  ServiceDeliveryPointType,
} from 'types/api-types'
import {useForm, SubmitHandler} from 'react-hook-form'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from 'next-i18next'
import Toggle from 'react-toggle'
import ChevronRight from '../../../../public/images/icon-chevron-right.svg'
import ChevronLeft from '../../../../public/images/icon-chevron-left.svg'
import Image from 'next/image'
import ReactSlider from 'react-slider'
import OutsideClickHandler from 'react-outside-click-handler'
import {constructDeliveryPointSearchRequestBody} from 'util/servicePointLocatorWebService'
import * as Sentry from '@sentry/react'
import MapUtils from 'util/mapUtils'
import ButtonBlock from 'components/shared/ButtonBlock'
import {useDebouncedCallback} from 'use-debounce'
import classNames from 'classnames'
import {getConnectorIcon} from 'util/connectorsType'
import {Analytics} from 'lib/analytics'
import {usePrevious} from '@uidotdev/usehooks'
import {difference} from 'lodash'
import IconBolt from 'components/icons/Bolt'

type ServicePointFiltersProps = {
  map: google.maps.Map
  selectedServiceType: ServiceDeliveryPointType
  fuelTypeFilters: FuelFilters | undefined
  chargingTypeFilters: ChargingFilters | undefined
  searchTerm?: string
  setFuelTypeFilters: (value: string[]) => void
  setChargingTypeFilters: (value: ChargingFilters) => void
  didFindResults: (results: ServiceDeliveryPoint[]) => void
  didResetFilters: () => void
  shouldClose: () => void
}

const sliderValuesMapping = {
  '0': 3.7,
  '25': 22,
  '50': 50,
  '75': 150,
  '100': 350,
}

const fuelTypes: {
  [key: string]: {filter: string; localizedKey: string; analyticsName: string}
} = {
  adblue: {
    filter: 'A',
    localizedKey: 'label_fuel_adblue',
    analyticsName: 'adblue',
  },
  cng: {
    filter: 'C',
    localizedKey: 'label_fuel_cng',
    analyticsName: 'cng',
  },
  diesel: {
    filter: 'D',
    localizedKey: 'label_fuel_diesel',
    analyticsName: 'diesel',
  },
  super98: {
    filter: 'P',
    localizedKey: 'label_fuel_super+98',
    analyticsName: 'super98',
  },
  euro95: {
    filter: 'U',
    localizedKey: 'label_fuel_euro95_e10',
    analyticsName: "euro95'",
  },
  hydrogen: {
    filter: 'W',
    localizedKey: 'label_fuel_hydrogen',
    analyticsName: 'hydrogen',
  },
}

const chargingConnectorTypes: {
  [key: string]: {
    filter: ChargingConnectorType
    localizedKey: string
  }
} = {
  type1: {
    filter: ChargingConnectorType.type1,
    localizedKey: 'label_charging_port_type1',
  },
  type2: {
    filter: ChargingConnectorType.type2,
    localizedKey: 'label_charging_port_type2',
  },
  type3: {
    filter: ChargingConnectorType.type3,
    localizedKey: 'label_charging_port_type3',
  },
  cHAdeMO: {
    filter: ChargingConnectorType.cHAdeMO,
    localizedKey: 'label_charging_port_chademo',
  },
  ccs1: {
    filter: ChargingConnectorType.ccs1,
    localizedKey: 'label_charging_port_ccs1',
  },
  ccs2: {
    filter: ChargingConnectorType.ccs2,
    localizedKey: 'label_charging_port_ccs2',
  },
  schukoBEFR: {
    filter: ChargingConnectorType.schukoBEFR,
    localizedKey: 'label_charging_port_schuko_be_fr',
  },
  schukoDENL: {
    filter: ChargingConnectorType.schukoDENL,
    localizedKey: 'label_charging_port_schuko_de_nl',
  },
  schukoEURO: {
    filter: ChargingConnectorType.schukoEURO,
    localizedKey: 'label_charging_port_schuko_euro',
  },
}

enum AnalyticsFilters {
  onlyDATS24 = 'only DATS24',
  onlyAvailable = 'only available',
  connectors = 'connectors',
  minimumPower = 'minimumPower',
}

export type ChargingFilters = {
  operators: ServiceDeliveryPointOperator[]
  availability: ServiceDeliveryPointAvailability[]
  connectors: ChargingConnectorType[]
  minimumPower: number
}

export type FuelFilters = string[]

type Inputs = {[key: string]: boolean}

const ServicePointFilters = ({
  map,
  selectedServiceType,
  fuelTypeFilters,
  chargingTypeFilters,
  searchTerm,
  setFuelTypeFilters,
  setChargingTypeFilters,
  didFindResults,
  didResetFilters,
  shouldClose,
}: ServicePointFiltersProps) => {
  const {t} = useTranslation()

  const {register, handleSubmit, reset, setValue, getValues, watch} =
    useForm<Inputs>()

  const [didParseInitialFuelFilters, setDidParseInitialFuelFilters] =
    useState(false)
  const [didParseInitialChargingFilters, setDidParseInitialChargingFilters] =
    useState(false)

  // Filter: Only show DATS 24 charging points
  const [onlyDATS24, setOnlyDATS24] = useState<boolean>(false)

  // Filter: Show only available charging points
  const [onlyAvailable, setOnlyAvailable] = useState<boolean>(false)

  // Filter: Available charging connectors and whether or not to display the
  // filter subview for them.
  const [displayConnectors, setDisplayConnectors] = useState<boolean>(false)
  const [connectors, setConnectors] = useState<ChargingConnectorType[]>([])

  // Filter: Minimum power output of the charging point
  const [minimumPower, setMinimumPower] = useState<number>(
    sliderValuesMapping[0],
  )
  const previousMinimumPower = usePrevious<number>(minimumPower)

  const [lastChangedFilterField, setLastChangedFilterField] = useState<
    {field: string; value?: string | number; is_removal: boolean} | undefined
  >()

  const [shouldSubmitChargingFilters, setShouldSubmitChargingFilters] =
    useState<boolean>(false)

  // The following state is used to track the current filter settings and
  // fetch the expected number of results.
  const [tmpFuelFilters, setTmpFuelFilters] = useState<FuelFilters>()
  const previousFuelFilters = usePrevious<FuelFilters | undefined>(
    tmpFuelFilters,
  )

  const [tmpConnectors, setTmpConnectors] = useState<ChargingConnectorType[]>(
    [],
  )
  const previousConnectors = usePrevious<ChargingConnectorType[]>(tmpConnectors)

  const [tmpChargingFilters, setTmpChargingFilters] =
    useState<ChargingFilters>()
  const [serviceDeliveryPoints, setServiceDeliveryPoints] = useState<
    ServiceDeliveryPoint[]
  >([])

  const connectorsMenuItemClassnames = classNames(
    'flex w-full items-center justify-between border-b border-dats-s9 px-4 ',
    {
      'h-[80px]': connectors.length == 0,
      'h-[108px]': connectors.length > 0,
    },
  )

  const fetchServiceDeliveryPoints = React.useCallback(
    async (map: google.maps.Map) => {
      const position = map.getCenter()
      if (!position) {
        return
      }

      const radius = MapUtils.calculateSearchRadius(map)
      let body = constructDeliveryPointSearchRequestBody(
        position.lat(),
        position.lng(),
        radius,
        selectedServiceType,
        tmpFuelFilters,
        tmpChargingFilters,
      )

      try {
        const response = await fetch(
          `https://${process.env
            .NEXT_PUBLIC_DOMAIN!}/api/service_point_locator`,
          {
            method: 'POST',
            body: JSON.stringify(body),
          },
        )
        const data: ServiceDeliveryPointSearchResponse = await response.json()

        if (response.ok) {
          // Take only the points visible in the rectangular map.
          let visibleRegion = map.getBounds()
          let visiblePoints: ServiceDeliveryPoint[] = []
          if (visibleRegion) {
            visiblePoints = data.serviceDeliveryPoints.filter(deliveryPoint => {
              let location = new google.maps.LatLng(
                deliveryPoint.latitude,
                deliveryPoint.longitude,
              )
              return MapUtils.boundsContainsLocation(visibleRegion!, location)
            })
          } else {
            visiblePoints = data.serviceDeliveryPoints
          }

          setServiceDeliveryPoints(visiblePoints)
        } else {
          throw {
            msg: 'Error fetching Filter SDP: ' + response.statusText,
            status: response.status,
          }
        }
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedServiceType,
      tmpFuelFilters,
      tmpChargingFilters,
      constructDeliveryPointSearchRequestBody,
    ],
  )

  const getIntValueFromSlider = () => {
    const strValue = Object.keys(sliderValuesMapping).find(
      x =>
        sliderValuesMapping[x as keyof typeof sliderValuesMapping] ===
        minimumPower,
    )
    return Number.parseInt(strValue ?? '0')
  }

  // Debounces fetchServiceDeliveryPoint calls.
  const debouncedFetchServiceDeliveryPoints = useDebouncedCallback(map => {
    fetchServiceDeliveryPoints(map)
  }, 500)

  const debouncedSendAnalytics = useDebouncedCallback(
    (serviceDeliveryPointCount, lastChangedFilterField) => {
      sendAnalytics(serviceDeliveryPointCount, lastChangedFilterField)
    },
    500,
  )

  // Helper effects to prefetch the number of expected results.

  /// Constructs the ChargingFilters object used to prefetch the results.
  const updateTmpChargingFilters = React.useCallback(() => {
    const chargingFilters: ChargingFilters = {
      operators: onlyDATS24
        ? [ServiceDeliveryPointOperator.dats]
        : [ServiceDeliveryPointOperator.all],
      availability: onlyAvailable
        ? [ServiceDeliveryPointAvailability.available]
        : [],
      connectors: tmpConnectors,
      minimumPower: minimumPower,
    }
    setTmpChargingFilters(chargingFilters)

    // NOTE: We don't want to run this hook when the previous values change,
    // only when the current ones change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyDATS24, onlyAvailable, tmpConnectors, minimumPower])

  /// Watches value changes in order to run the service point
  /// request and display the number of expected results.
  useEffect(() => {
    const subscription = watch(value => {
      if (selectedServiceType === ServiceDeliveryPointType.fuel) {
        const filteredData = Object.entries(value)
          .filter(([, value]) => value === true)
          .map(([key]) => key)

        setTmpFuelFilters(filteredData)
      } else {
        const selectedConnectors = Object.entries(value)
          .filter(([, value]) => value === true)
          .map(([key]) => key as ChargingConnectorType)
        setTmpConnectors(selectedConnectors)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, selectedServiceType, updateTmpChargingFilters, previousConnectors])

  // When tmpFuelFilters is set, it takes a while for the
  // previousFuelFilters state to become up to date. Therefore
  // this useEffect.
  useEffect(() => {
    if (previousFuelFilters) {
      let diffAdded = difference(tmpFuelFilters, previousFuelFilters)
      let diffSubtracted = difference(previousFuelFilters, tmpFuelFilters ?? [])

      if (diffAdded.length > 0) {
        const key = Object.keys(fuelTypes).find(
          key => fuelTypes[key].filter === diffAdded[0],
        )

        if (key) {
          const value = fuelTypes[key].analyticsName
          analyticsDidChangeFuelFilter(value, false)
        }
      } else if (diffSubtracted.length > 0) {
        const key = Object.keys(fuelTypes).find(
          key => fuelTypes[key].filter === diffSubtracted[0],
        )

        if (key) {
          const value = fuelTypes[key].analyticsName
          analyticsDidChangeFuelFilter(value, true)
        }
      }
    } else if (tmpFuelFilters && tmpFuelFilters.length === 1) {
      const key = Object.keys(fuelTypes).find(
        key => fuelTypes[key].filter === tmpFuelFilters[0],
      )

      if (key) {
        const value = fuelTypes[key].analyticsName
        analyticsDidChangeFuelFilter(value, false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousFuelFilters])

  // When tmpConnectors is set, it takes a while for the
  // previousConnectors state to become up to date. Therefore
  // this useEffect.
  useEffect(() => {
    if (previousConnectors) {
      let diffAdded = difference(tmpConnectors, previousConnectors)
      let diffSubtracted = difference(previousConnectors, tmpConnectors ?? [])

      if (diffAdded.length > 0) {
        const key = Object.keys(chargingConnectorTypes).find(
          key => chargingConnectorTypes[key].filter === diffAdded[0],
        )

        if (key) {
          const value = `connector ${key}`
          analyticsDidChangeConnector(value, false)
        }
      } else if (diffSubtracted.length > 0) {
        const key = Object.keys(chargingConnectorTypes).find(
          key => chargingConnectorTypes[key].filter === diffSubtracted[0],
        )

        if (key) {
          const value = `connector ${key}`
          analyticsDidChangeConnector(value, true)
        }
      }
    } else if (tmpConnectors && tmpConnectors.length === 1) {
      const key = Object.keys(chargingConnectorTypes).find(
        key => chargingConnectorTypes[key].filter === tmpConnectors[0],
      )

      if (key) {
        const value = `connector ${key}`
        analyticsDidChangeConnector(value, false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousConnectors])

  /// Updates the temp charging filters used to prefetch results.
  useEffect(() => {
    if (selectedServiceType === ServiceDeliveryPointType.charge) {
      updateTmpChargingFilters()
    }
  }, [
    selectedServiceType,
    onlyDATS24,
    onlyAvailable,
    tmpConnectors,
    minimumPower,
    updateTmpChargingFilters,
  ])

  useEffect(() => {
    // Clear any analytics related stuff.
    setLastChangedFilterField(undefined)
  }, [selectedServiceType])

  /// Prefetches results whenever our temp filters change.
  useEffect(() => {
    if (!map || selectedServiceType === ServiceDeliveryPointType.charge) {
      return
    }

    debouncedFetchServiceDeliveryPoints(map)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpFuelFilters, map])

  useEffect(() => {
    if (!map || selectedServiceType === ServiceDeliveryPointType.fuel) {
      return
    }

    debouncedFetchServiceDeliveryPoints(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpChargingFilters, map])

  // Filter functionality

  const onReset = () => {
    reset()
    setConnectors([])
    setOnlyDATS24(false)
    setOnlyAvailable(false)
    setMinimumPower(3.7)
    setFuelTypeFilters([])
    didResetFilters()
  }

  const onShouldDisplayConnectors = () => {
    setDisplayConnectors(true)
  }

  const onMinimumPowerChanged = (value: number) => {
    if (value > 4) {
      value = Math.ceil(value)
    }
    setMinimumPower(value)
  }

  const onLeavingChargingConnectors = () => {
    var selectedConnectors: ChargingConnectorType[] = []
    const selectedChargingConnectorValues = getValues()
    Object.keys(selectedChargingConnectorValues).forEach(connector => {
      if (selectedChargingConnectorValues[connector] === true) {
        selectedConnectors.push(connector as ChargingConnectorType)
      }
    })
    setConnectors(selectedConnectors)
    setDisplayConnectors(false)
  }

  const onHandleChargingConnectorsSubmit: SubmitHandler<Inputs> = data => {
    const selectedConnectors = Object.entries(data)
      .filter(([, value]) => value === true)
      .map(([key]) => key as ChargingConnectorType)
    setConnectors(selectedConnectors)
  }

  const submitChargingFilters = React.useCallback(() => {
    const chargingFilters: ChargingFilters = {
      operators: onlyDATS24
        ? [ServiceDeliveryPointOperator.dats]
        : [ServiceDeliveryPointOperator.all],
      availability: onlyAvailable
        ? [ServiceDeliveryPointAvailability.available]
        : [],
      connectors: connectors,
      minimumPower: minimumPower,
    }

    setChargingTypeFilters(chargingFilters)
    didFindResults(serviceDeliveryPoints)
  }, [
    connectors,
    minimumPower,
    onlyAvailable,
    onlyDATS24,
    setChargingTypeFilters,
    didFindResults,
    serviceDeliveryPoints,
  ])

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (selectedServiceType === ServiceDeliveryPointType.fuel) {
      const filteredData = Object.entries(data)
        .filter(([, value]) => value === true)
        .map(([key]) => key)

      setFuelTypeFilters(filteredData)
    } else {
      onHandleChargingConnectorsSubmit(data)
      // We don't directly call submitChargingFilters() here because
      // the state of the previous operation might not yet have finished
      // updating. Therefore we set a flag and the above useEffect will
      // submit when the state is ok.
      setShouldSubmitChargingFilters(true)
    }

    didFindResults(serviceDeliveryPoints)
  }

  // Restore filters.
  useEffect(() => {
    if (fuelTypeFilters && !didParseInitialFuelFilters) {
      setDidParseInitialFuelFilters(true)
      for (const fuelType of fuelTypeFilters) {
        setValue(fuelType, true)
      }
    }

    if (chargingTypeFilters && !didParseInitialChargingFilters) {
      setDidParseInitialChargingFilters(true)

      if (
        chargingTypeFilters.operators.length === 1 &&
        chargingTypeFilters.operators[0] === ServiceDeliveryPointOperator.dats
      ) {
        setOnlyDATS24(true)
      }
      if (
        chargingTypeFilters.availability.length === 1 &&
        chargingTypeFilters.availability[0] ===
          ServiceDeliveryPointAvailability.available
      ) {
        setOnlyAvailable(true)
      }

      setConnectors(chargingTypeFilters.connectors)
      for (const connector of chargingTypeFilters.connectors) {
        setValue(connector, true)
      }
      setMinimumPower(chargingTypeFilters.minimumPower)
    }
  }, [
    fuelTypeFilters,
    chargingTypeFilters,
    setValue,
    didParseInitialFuelFilters,
    didParseInitialChargingFilters,
  ])

  useEffect(() => {
    if (shouldSubmitChargingFilters) {
      setShouldSubmitChargingFilters(false)
      submitChargingFilters()
    }
  }, [connectors, shouldSubmitChargingFilters, submitChargingFilters])

  const hasFiltersSelected = () => {
    if (selectedServiceType === ServiceDeliveryPointType.fuel) {
      if (tmpFuelFilters && tmpFuelFilters.length > 0) {
        return true
      }
      return false
    } else {
      return (
        onlyDATS24 ||
        onlyAvailable ||
        minimumPower > 3.7 ||
        connectors.length > 0
      )
    }
  }

  const formatShowResultsLabel = () => {
    if (selectedServiceType === ServiceDeliveryPointType.fuel) {
      if (hasFiltersSelected()) {
        const resultCount = serviceDeliveryPoints.length
        if (resultCount === 0) {
          return t('label_service_point_locator_filter_show_no_fuel_results')
        }

        return t(
          'label_service_point_locator_filter_show_fuel_results',
        ).replace('||AMOUNT||', `${resultCount}`)
      } else {
        return t('label_service_point_locator_filter_show_all_fuel_stations')
      }
    } else {
      if (hasFiltersSelected()) {
        const resultCount = serviceDeliveryPoints.length
        if (resultCount === 0) {
          return t(
            'label_service_point_locator_filter_show_no_charging_results',
          )
        }

        return t(
          'label_service_point_locator_filter_show_charging_results',
        ).replace('||AMOUNT||', `${resultCount}`)
      } else {
        return t('label_service_point_locator_filter_show_all_charging_points')
      }
    }
  }

  const formatConnector = (connector: ChargingConnectorType) => {
    return Object.keys(chargingConnectorTypes).find(connectorType => {
      const data = chargingConnectorTypes[connectorType]
      if (data.filter == connector) {
        return t(data.localizedKey)
      }
    })
  }

  const isResultsButtonDisabled = () => {
    if (hasFiltersSelected()) {
      return serviceDeliveryPoints.length === 0
    }
    return false
  }

  /* Analytics */

  const analyticsDidChangeOnlyDATS24 = (value: boolean) => {
    setLastChangedFilterField({
      field: AnalyticsFilters.onlyDATS24,
      is_removal: !value,
    })
  }

  const analyticsDidChangeOnlyAvailable = (value: boolean) => {
    setLastChangedFilterField({
      field: AnalyticsFilters.onlyAvailable,
      is_removal: !value,
    })
  }

  const analyticsDidChangeMinimumPower = (value: number) => {
    // The minimum power component sends an event when we leave
    // the connectors subview. Only consider it an actual change
    // when the value changed.
    if (value != previousMinimumPower) {
      setLastChangedFilterField({
        field: AnalyticsFilters.minimumPower,
        value: value,
        is_removal: false,
      })
    }
  }

  const analyticsDidChangeConnector = (field: string, is_removal: boolean) => {
    setLastChangedFilterField({
      field: field,
      is_removal: is_removal,
    })
  }

  const analyticsDidChangeFuelFilter = (field: string, is_removal: boolean) => {
    setLastChangedFilterField({
      field: field,
      is_removal: is_removal,
    })
  }

  useEffect(() => {
    if (lastChangedFilterField) {
      debouncedSendAnalytics(
        serviceDeliveryPoints.length,
        lastChangedFilterField,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDeliveryPoints])

  const sendAnalytics = (
    serviceDeliveryPointCount: number,
    lastChangedFilterField: {
      field: string
      value?: string | number
      is_removal: boolean
    },
  ) => {
    let list_filters: {name: string; category: string}[] = []
    let active_list_filters: {name: string; category: string}[] = []
    let is_removal = false
    const category =
      selectedServiceType === ServiceDeliveryPointType.charge
        ? 'charging'
        : 'fueling'

    if (lastChangedFilterField) {
      list_filters.push({
        name: lastChangedFilterField.value
          ? `${lastChangedFilterField.field} ${lastChangedFilterField.value}`
          : lastChangedFilterField.field,
        category: category,
      })
      is_removal = lastChangedFilterField.is_removal

      if (!is_removal) {
        active_list_filters.push({
          name: lastChangedFilterField.value
            ? `${lastChangedFilterField.field} ${lastChangedFilterField.value}`
            : lastChangedFilterField.field,
          category: category,
        })
      }
    }

    // Collect all active filters apart from the one just changed.

    if (selectedServiceType === ServiceDeliveryPointType.charge) {
      if (
        onlyDATS24 &&
        lastChangedFilterField?.field !== AnalyticsFilters.onlyDATS24
      ) {
        active_list_filters.push({
          name: 'only dats24',
          category: category,
        })
      }

      if (
        onlyAvailable &&
        lastChangedFilterField?.field !== AnalyticsFilters.onlyAvailable
      ) {
        active_list_filters.push({
          name: 'only available',
          category: category,
        })
      }

      if (
        minimumPower &&
        lastChangedFilterField?.field != AnalyticsFilters.minimumPower
      ) {
        active_list_filters.push({
          name: `mimimumPower ${minimumPower}`,
          category: category,
        })
      }

      tmpConnectors?.forEach((filter: string) => {
        const key = Object.keys(chargingConnectorTypes).find(
          key => chargingConnectorTypes[key].filter === filter,
        )
        if (key) {
          const value = `connector ${key}`
          if (
            lastChangedFilterField &&
            value !== lastChangedFilterField?.field
          ) {
            active_list_filters.push({
              name: value,
              category: category,
            })
          }
        }
      })
    } else {
      tmpFuelFilters?.forEach((filter: string) => {
        const key = Object.keys(fuelTypes).find(
          key => fuelTypes[key].filter === filter,
        )
        if (key) {
          const value = fuelTypes[key].analyticsName
          if (
            lastChangedFilterField &&
            value !== lastChangedFilterField?.field
          ) {
            active_list_filters.push({
              name: value,
              category: category,
            })
          }
        }
      })
    }

    Analytics.getInstance().sendStoreListFiltersEvent(
      is_removal,
      searchTerm ?? '',
      serviceDeliveryPointCount,
      list_filters,
      active_list_filters,
    )
  }

  return (
    <div className="absolute z-50 size-full rounded-dats border border-dats-border-gray bg-white shadow-md shadow-black/5 md:absolute md:right-4 md:top-20 md:h-auto md:w-[380px]">
      <OutsideClickHandler onOutsideClick={shouldClose} useCapture={true}>
        <div className="h-[60px] border-b border-dats-s9">
          <div className="flex h-full items-center justify-between space-x-2 px-4">
            <div className="flex items-center justify-start space-x-2">
              <div className="size-5">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 13 13"
                  className="fill-dats-s5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.4431 0.987061C11.9541 0.987061 12.2096 1.59099 11.838 1.93941L7.56399 6.23661V12.3224C7.56399 12.7869 7.05297 13.0425 6.68132 12.7869L4.82307 11.4862C4.66047 11.3933 4.59079 11.2074 4.59079 11.0216V6.23661L0.293589 1.93941C-0.078061 1.59099 0.177448 0.987061 0.688467 0.987061H11.4431Z" />
                </svg>
              </div>
              <span className="font-rubik font-bold text-dats-s5">
                {t('label_service_point_locator_filter_title')}
              </span>
            </div>
            <button onClick={shouldClose} className="block md:hidden">
              <FontAwesomeIcon
                icon={faXmark}
                style={{color: '#005E75'}}
                width={20}
                height={20}
              />
            </button>
          </div>
        </div>

        {selectedServiceType === ServiceDeliveryPointType.fuel ? (
          <div className="">
            <div className="h-[60px] items-center justify-start border-b border-dats-s9">
              <div className="flex h-full items-center justify-start px-4">
                <span className="font-rubik font-medium text-dats-s5">
                  {t('label_service_point_locator_filter_alternatives')}
                </span>
              </div>
            </div>

            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="max-h-full overflow-auto md:max-h-[300px]">
                {Object.keys(fuelTypes).map(fuelType => (
                  <div
                    key={fuelTypes[fuelType].filter}
                    className="h-[60px] border-b border-dats-s9"
                  >
                    <div className="flex h-full items-center justify-between px-4">
                      <label
                        htmlFor={fuelTypes[fuelType].filter}
                        className="flex w-full justify-between"
                      >
                        <span className="font-body text-dats-s5">
                          {t(fuelTypes[fuelType].localizedKey)}
                        </span>
                        <input
                          type="checkbox"
                          className="checked:bg-dats-green size-[20px] cursor-pointer rounded-[4px] border-2 border-dats-s3 bg-white text-dats-s3 accent-dats-s3 focus:ring-0 focus:ring-offset-0"
                          {...register(fuelTypes[fuelType].filter)}
                          id={fuelTypes[fuelType].filter}
                        />
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col space-y-4 p-4">
                <div className="mx-auto flex h-[46px] w-full items-center justify-center">
                  <ButtonBlock
                    title={formatShowResultsLabel()}
                    styling="primary"
                    type="submit"
                    className="w-full"
                    disabled={isResultsButtonDisabled()}
                  />
                </div>

                <button
                  className="mx-auto flex w-auto items-center justify-center bg-white font-body text-body font-semibold text-dats-s3 underline"
                  onClick={() => onReset()}
                >
                  {t('label_service_point_locator_filter_reset')}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <>
            {!displayConnectors ? (
              <div className="flex max-h-[500px] flex-col items-center justify-start overflow-auto">
                <div className="flex h-[80px] w-full items-center justify-between border-b border-dats-s9 px-4 md:h-[70px]">
                  <div className="pr-[17px]">
                    <p className="font-rubik text-body font-medium text-dats-s5">
                      {t(
                        'label_service_point_locator_filter_only_dats24_title',
                      )}
                    </p>
                    <p className="font-body text-[12px] text-dats-s5">
                      {t(
                        'label_service_point_locator_filter_only_dats24_subtitle',
                      )}
                    </p>
                  </div>
                  <div>
                    <label>
                      <Toggle
                        checked={onlyDATS24}
                        icons={false}
                        onChange={e => {
                          analyticsDidChangeOnlyDATS24(e.target.checked)
                          setOnlyDATS24(e.target.checked)
                        }}
                      />
                    </label>
                  </div>
                </div>

                <div className="flex h-[69px] w-full items-center justify-between border-b border-dats-s9 px-4">
                  <div className="pr-[17px]">
                    <p className="font-rubik text-body font-medium text-dats-s5">
                      {t(
                        'label_service_point_locator_filter_availability_title',
                      )}
                    </p>
                    <p className="font-body text-[12px] text-dats-s5">
                      {t(
                        'label_service_point_locator_filter_availability_subtitle',
                      )}
                    </p>
                  </div>
                  <div>
                    <label>
                      <Toggle
                        checked={onlyAvailable}
                        icons={false}
                        onChange={e => {
                          analyticsDidChangeOnlyAvailable(e.target.checked)
                          setOnlyAvailable(e.target.checked)
                        }}
                      />
                    </label>
                  </div>
                </div>

                <button
                  onClick={onShouldDisplayConnectors}
                  type="button"
                  className={connectorsMenuItemClassnames}
                >
                  <div className="flex flex-col items-start justify-center">
                    <div className="flex w-full items-center justify-between">
                      <div className="text-left">
                        <p className="font-rubik text-body font-medium text-dats-s5">
                          {t(
                            'label_service_point_locator_filter_connectors_title',
                          )}
                        </p>
                        <p className="font-body text-[12px] text-dats-s5">
                          {t(
                            'label_service_point_locator_filter_connectors_subtitle',
                          )}
                        </p>
                      </div>
                    </div>

                    {connectors.length > 0 ? (
                      <div className="flex flex-wrap items-center justify-start">
                        {connectors.map(connector => (
                          <div
                            key={connector}
                            className="mb-1 mr-2 rounded-sm bg-dats-s9 p-1"
                          >
                            <p className="font-body text-[10px] text-dats-s5">
                              {formatConnector(connector)}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <Image
                      src={ChevronRight}
                      alt="chevron"
                      unoptimized={true}
                    />
                  </div>
                </button>

                <div className="h-[135px] w-full border-b border-dats-s9 p-4">
                  <div className="flex w-full flex-col">
                    <div className="flex w-full items-center justify-between">
                      <p className="font-rubik text-body font-medium text-dats-s5">
                        {t('label_service_point_locator_filter_minimum_power')}
                      </p>
                      <p className="font-body text-[11px] text-dats-s6">
                        {minimumPower === 350 ? '+' : ''}
                        {minimumPower} kW
                      </p>
                    </div>

                    <div className="mt-2 w-full">
                      <div className="relative">
                        <ReactSlider
                          className="horizontal-slider"
                          thumbClassName="thumb"
                          trackClassName="track"
                          min={0}
                          max={100}
                          step={25}
                          value={getIntValueFromSlider()}
                          defaultValue={getIntValueFromSlider()}
                          marks={[
                            ...Object.keys(sliderValuesMapping).map(x =>
                              Number.parseInt(x),
                            ),
                          ]}
                          renderMark={props => {
                            const value = getIntValueFromSlider()
                            if ((props.key as number) > value) {
                              props.className = 'mark mark-uncompleted'
                            }

                            if (
                              typeof props.style?.left === 'number' &&
                              props.key
                            ) {
                              props.style.left +=
                                ((props.key as number) / 25) * 3
                            }

                            return (
                              <div {...props}>
                                <div
                                  className={classNames(
                                    'absolute top-6 font-body text-[11px] text-dats-s6',
                                    {
                                      '-translate-x-[6px]':
                                        (props.key as number) === 75,
                                      'left-0': (props.key as number) === 0,
                                      'right-0': (props.key as number) === 100,
                                    },
                                  )}
                                >
                                  {(props.key === 100 ? '+' : '') +
                                    sliderValuesMapping[
                                      props.key as keyof typeof sliderValuesMapping
                                    ]}
                                  <span className="mt-2 flex">
                                    {[0, 50, 75].includes(
                                      props.key as number,
                                    ) ? (
                                      <IconBolt />
                                    ) : null}
                                    {[50, 75].includes(props.key as number) ? (
                                      <IconBolt />
                                    ) : null}
                                    {(props.key as number) === 75 ? (
                                      <IconBolt />
                                    ) : null}
                                  </span>
                                </div>
                              </div>
                            )
                          }}
                          onChange={e => {
                            const mappedValue =
                              sliderValuesMapping[
                                e as unknown as keyof typeof sliderValuesMapping
                              ]
                            analyticsDidChangeMinimumPower(mappedValue)
                            onMinimumPowerChanged(mappedValue)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex w-full flex-col space-y-4 p-4">
                  <div className="mx-auto flex h-[46px] w-full items-center justify-center">
                    <ButtonBlock
                      onClick={submitChargingFilters}
                      title={formatShowResultsLabel()}
                      styling="primary"
                      type="submit"
                      className="w-full"
                      disabled={isResultsButtonDisabled()}
                    />
                  </div>
                  <button
                    className="mx-auto flex w-auto items-center justify-center bg-white font-body text-body font-semibold text-dats-s3 underline"
                    onClick={() => onReset()}
                  >
                    {t('label_service_point_locator_filter_reset')}
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex max-h-[500px] flex-col items-start justify-start">
                <button
                  onClick={onLeavingChargingConnectors}
                  className="flex h-[80px] w-full items-center justify-start border-b border-dats-s9 px-4"
                >
                  <div className="-ml-4 -mt-5">
                    <Image
                      src={ChevronLeft}
                      alt="chevron"
                      width={36}
                      height={36}
                      unoptimized={true}
                    />
                  </div>
                  <div className="w-full text-left">
                    <p className="font-rubik font-medium text-dats-s5">
                      {t('label_service_point_locator_filter_connectors_title')}
                    </p>
                    <p className="font-body text-[12px] text-dats-s5">
                      {t(
                        'label_service_point_locator_filter_connectors_subtitle',
                      )}
                    </p>
                  </div>
                </button>

                <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                  <div className="max-h-[300px] overflow-auto">
                    {Object.keys(chargingConnectorTypes).map(connectorType => (
                      <div
                        key={chargingConnectorTypes[connectorType].filter}
                        className="h-[60px] border-b border-dats-s9"
                      >
                        <div className="flex h-full items-center justify-between space-x-4 px-4">
                          <Image
                            src={getConnectorIcon(
                              chargingConnectorTypes[connectorType].filter,
                            )}
                            alt="chevron"
                            width={24}
                            height={24}
                            unoptimized={true}
                          />
                          <label
                            htmlFor={
                              chargingConnectorTypes[connectorType].filter
                            }
                            className="flex w-full justify-between"
                          >
                            <span className="font-body text-dats-s5">
                              {t(
                                chargingConnectorTypes[connectorType]
                                  .localizedKey,
                              )}
                            </span>
                            <input
                              type="checkbox"
                              className="checked:bg-dats-green size-[20px] cursor-pointer rounded-[4px] border-2 border-dats-s3 bg-white text-dats-s3 accent-dats-s3 focus:ring-0 focus:ring-offset-0"
                              {...register(
                                chargingConnectorTypes[connectorType].filter,
                              )}
                              id={chargingConnectorTypes[connectorType].filter}
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex w-full flex-col space-y-4 p-4">
                    <div className="mx-auto flex h-[46px] w-full items-center justify-center">
                      <ButtonBlock
                        title={formatShowResultsLabel()}
                        styling="primary"
                        type="submit"
                        className="w-full"
                        disabled={isResultsButtonDisabled()}
                      />
                    </div>
                    <button
                      className="mx-auto flex w-auto items-center justify-center bg-white font-body text-body font-semibold text-dats-s3 underline"
                      onClick={() => onReset()}
                    >
                      {t('label_service_point_locator_filter_reset')}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}
      </OutsideClickHandler>
    </div>
  )
}

export default ServicePointFilters
