import * as React from 'react'
import {SVGProps} from 'react'

const IconBolt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_10239_37483)">
      <path
        d="M9.75987 2.06025C9.91658 1.69636 9.79971 1.27137 9.47831 1.03763C9.15692 0.80389 8.71865 0.82514 8.41851 1.08544L1.61875 7.03523C1.35313 7.26897 1.25751 7.64349 1.38235 7.97286C1.50719 8.30222 1.82593 8.52534 2.1792 8.52534H5.14081L3.09823 13.2905C2.94151 13.6544 3.05838 14.0794 3.37978 14.3131C3.70117 14.5468 4.13944 14.5256 4.43958 14.2653L11.2393 8.3155C11.505 8.08176 11.6006 7.70724 11.4757 7.37788C11.3509 7.04851 11.0348 6.82805 10.6789 6.82805H7.71728L9.75987 2.06025Z"
        fill="#676464"
      />
    </g>
    <defs>
      <clipPath id="clip0_10239_37483">
        <rect
          x="0.428711"
          y="0.675415"
          width="12"
          height="14"
          rx="2.04"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
)
export default IconBolt
