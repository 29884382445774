import React from 'react'

interface ServicePointZoomButtonsProps {
  onZoomInClicked: () => void
  onZoomOutClicked: () => void
}

function ServicePointZoomButtons({
  onZoomInClicked,
  onZoomOutClicked,
}: ServicePointZoomButtonsProps) {
  return (
    <div className="flex h-auto w-[47px] flex-col items-center justify-around rounded-dats bg-white shadow-dats">
      <button
        onClick={onZoomInClicked}
        className="hover:bg-slate-100 w-full rounded-dats"
      >
        <div className="flex h-[42px] items-center justify-center">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0.0117188C8.5523 0.0117188 9 0.459439 9 1.01172V7.01172H15C15.5523 7.01172 16 7.45942 16 8.01172C16 8.56402 15.5523 9.01172 15 9.01172H9V15.0117C9 15.564 8.5523 16.0117 8 16.0117C7.4477 16.0117 7 15.564 7 15.0117V9.01172H1C0.44772 9.01172 0 8.56402 0 8.01172C0 7.45942 0.44772 7.01172 1 7.01172H7V1.01172C7 0.459439 7.4477 0.0117188 8 0.0117188Z"
              fill="#3B4344"
            />
          </svg>
        </div>
      </button>
      <div className="h-px bg-dats-s8 px-4"></div>
      <button
        onClick={onZoomOutClicked}
        className="hover:bg-slate-100 w-full rounded-dats"
      >
        <div className="flex h-[42px] items-center justify-center">
          <svg
            width="16"
            height="3"
            viewBox="0 0 16 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.01172C0 0.459419 0.44772 0.0117188 1 0.0117188H15C15.5523 0.0117188 16 0.459419 16 1.01172C16 1.56402 15.5523 2.01172 15 2.01172H1C0.44772 2.01172 0 1.56402 0 1.01172Z"
              fill="#3B4344"
            />
          </svg>
        </div>
      </button>
    </div>
  )
}

export default ServicePointZoomButtons
