import {
  ChargingFilters,
  FuelFilters,
} from 'components/Nodes/ServicePointLocator/ServicePointFilters/ServicePointFilters'
import {
  ServiceDeliveryPointOperator,
  ServiceDeliveryPointSearchRequestBody,
  ServiceDeliveryPointType,
} from 'types/api-types'

export const constructDeliveryPointSearchRequestBody = (
  latitude: number,
  longitude: number,
  searchRadius: number,
  selectedServiceType: ServiceDeliveryPointType,
  fuelFilters?: FuelFilters,
  chargingFilters?: ChargingFilters,
) => {
  let body: ServiceDeliveryPointSearchRequestBody = {
    latitude: latitude,
    longitude: longitude,
    searchRadius: searchRadius,
    filterCriteria: {
      serviceDeliveryPointType:
        selectedServiceType === ServiceDeliveryPointType.fuel
          ? [ServiceDeliveryPointType.fuel]
          : [ServiceDeliveryPointType.charge],
      serviceDeliveryPointAvailability: chargingFilters
        ? chargingFilters.availability
        : [],
      chargingConnectorType: chargingFilters ? chargingFilters.connectors : [],
      chargingConnectorPowerType: [],
      chargingLocationOperatorName: chargingFilters
        ? chargingFilters.operators
        : [ServiceDeliveryPointOperator.all],
      fuelProductType: fuelFilters ? fuelFilters : [],
    },
  }

  if (chargingFilters) {
    body.filterCriteria.chargingConnectorPower = chargingFilters.minimumPower
  }

  return body
}
