import React from 'react'
import {useTranslation} from 'next-i18next'
import {ServiceDeliveryPointAvailability} from 'types/api-types'

type ServicePointChargerAvailibility = {
  availibility: ServiceDeliveryPointAvailability
}

const ServicePointChargerAvailibility = ({
  availibility,
}: ServicePointChargerAvailibility) => {
  const {t} = useTranslation()

  return (
    <>
      {availibility === 'available' ? (
        <div className="inline-flex items-center justify-start space-x-2 rounded-dats border border-dats-border-s2 px-2 py-0.5">
          <div className="size-2 rounded-full bg-dats-s2"></div>
          <p className="font-body text-[10px] font-semibold text-dats-s2">
            {t('label_service_point_locator_available')}
          </p>
        </div>
      ) : (
        <div className="inline-flex items-center justify-start space-x-2 rounded-dats border border-dats-neutral-lighter px-2 py-0.5">
          <div className="size-2 rounded-full bg-dats-neutral-darkest"></div>
          <p className="font-body text-[10px] font-semibold text-dats-neutral-darkest">
            {t('label_service_point_locator_unavailable')}
          </p>
        </div>
      )}
    </>
  )
}

export default ServicePointChargerAvailibility
