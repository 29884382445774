import Image from 'next/image'
import React from 'react'
import {useTranslation} from 'next-i18next'
import IconList from '../../../public/images/icon-list.svg'
import IconMap from '../../../public/images/icon-map.svg'

interface ServicePointListMapToggleButtonProps {
  onClicked: () => void
  isMapActive: boolean
}

function ServicePointListMapToggleButton({
  onClicked,
  isMapActive,
}: ServicePointListMapToggleButtonProps) {
  const {t} = useTranslation()

  return (
    <button onClick={onClicked}>
      <div className="hover:bg-slate-100 flex w-auto items-center justify-around space-x-[10px] rounded-full bg-white px-12 py-4 shadow-dats md:hidden">
        {isMapActive ? (
          <Image
            src={IconList}
            alt="Display list view"
            width={16}
            height={16}
            unoptimized={true}
          />
        ) : (
          <Image
            src={IconMap}
            alt="Display map view"
            width={16}
            height={16}
            unoptimized={true}
          />
        )}

        <p className="font-body text-[16px] font-semibold text-dats-s3">
          {isMapActive
            ? t('label_service_point_locator_button_list')
            : t('label_service_point_locator_button_map')}
        </p>
      </div>
    </button>
  )
}

export default ServicePointListMapToggleButton
