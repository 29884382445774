abstract class MapUtils {
  public static kmToZoomLevel = (km: number, latitude: number) => {
    return Math.log((38000 * Math.cos(latitude)) / km / Math.log(2)) + 3
  }

  public static zoomLevelToKm = (zoomLevel: number, latitude: number) => {
    if (zoomLevel <= 0) return 0
    return (38000 / Math.pow(2, zoomLevel - 3)) * Math.cos(latitude)
  }

  public static calculateSearchRadius = (
    map: google.maps.Map | undefined | null,
  ) => {
    let searchRadius: number = 10 // Default radius of 10km
    if (!map) {
      return searchRadius * 1000
    }

    return Math.round(MapUtils.getVisibleRadius(map) * 1000)
  }

  public static getSearchRadiusForIteration = (
    iteration: number,
    searchRadius: number,
    increaseInterval: number,
  ) => {
    return searchRadius + iteration * increaseInterval
  }

  public static getVisibleRadius = (map: google.maps.Map) => {
    let visibleRegion: google.maps.LatLngBounds | undefined = map.getBounds()
    if (visibleRegion) {
      let northEast: google.maps.LatLng = visibleRegion.getNorthEast()
      let southWest: google.maps.LatLng = visibleRegion.getSouthWest()
      let distance = MapUtils.distanceBetween(northEast, southWest)

      if (distance === 0) {
        return 10
      }
      return distance / 1000
    }
    return 10
  }

  public static distanceBetween = (
    northEast: google.maps.LatLng,
    southWest: google.maps.LatLng,
  ) => {
    return google.maps.geometry.spherical.computeDistanceBetween(
      northEast,
      southWest,
    )
  }

  public static boundsContainsLocation = (
    bounds: google.maps.LatLngBounds,
    location: google.maps.LatLng,
  ) => {
    let northEast = bounds.getNorthEast()
    let southWest = bounds.getSouthWest()
    let northWest = new google.maps.LatLng(northEast.lat(), southWest.lng())
    let southEast = new google.maps.LatLng(southWest.lat(), northEast.lng())

    const coords = [
      {lat: northEast.lat(), lng: northEast.lng()},
      {lat: southEast.lat(), lng: southEast.lng()},
      {lat: southWest.lat(), lng: southWest.lng()},
      {lat: northWest.lat(), lng: northWest.lng()},
    ]

    const adjustedCoords = this.adjustBoundary(coords)

    const rectangle = new google.maps.Polygon({paths: adjustedCoords})

    const containsLocation = google.maps.geometry.poly.containsLocation(
      location,
      rectangle,
    )

    return containsLocation
  }

  public static subtractBounds = (
    oldBounds: google.maps.LatLngBounds,
    newBounds: google.maps.LatLngBounds,
  ) => {
    const latNorth = oldBounds.getNorthEast().lat()
    const latSouth = oldBounds.getSouthWest().lat()
    const lngWest = oldBounds.getSouthWest().lng()
    const lngEast = newBounds.getSouthWest().lng()

    const northEast = new google.maps.LatLng(latNorth, lngEast)
    const southWest = new google.maps.LatLng(latSouth, lngWest)

    return {northEast, southWest}
  }

  // Calculate the outer bounds to fit all the given points in the map.
  // NOTE: This will only work for the Northern hemisphere!
  public static calculateMapBounds = (points: google.maps.LatLng[]) => {
    // Find SW
    let swLatitude = 90
    let swLongitude = 90
    for (const point of points) {
      const lat = point.lat()
      const lng = point.lng()

      if (lat < swLatitude) {
        swLatitude = lat
      }

      if (lng < swLongitude) {
        swLongitude = lng
      }
    }
    const southWest = new google.maps.LatLng(swLatitude, swLongitude)

    // Find NE
    let neLatitude = 0
    let neLongitude = 0
    for (const point of points) {
      const lat = point.lat()
      const lng = point.lng()

      if (lat > neLatitude) {
        neLatitude = lat
      }

      if (lng > neLongitude) {
        neLongitude = lng
      }
    }
    const northEast = new google.maps.LatLng(neLatitude, neLongitude)

    return new google.maps.LatLngBounds(southWest, northEast)
  }

  // Takes a center point and bounds and adjusts the bounds rectangle
  // so the center point actually becomes the center of the rectangle,
  // (if not already the case).
  public static adjustBoundsToKeepCenter = (
    center: google.maps.LatLng,
    bounds: google.maps.LatLngBounds,
  ) => {
    const neLat = bounds.getNorthEast().lat()
    const neLng = bounds.getNorthEast().lng()
    const swLat = bounds.getSouthWest().lat()
    const swLng = bounds.getSouthWest().lng()

    const centerLat = center.lat()
    const centerLng = center.lng()

    const latDifference = Math.max(
      Math.abs(neLat - centerLat),
      Math.abs(swLat - centerLat),
    )
    const lngDifference = Math.max(
      Math.abs(neLng - centerLng),
      Math.abs(swLng - centerLng),
    )

    const newSwLat = centerLat - latDifference
    const newNeLat = centerLat + latDifference
    const newSwLng = centerLng - lngDifference
    const newNeLng = centerLng + lngDifference

    const newBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(newSwLat, newSwLng),
      new google.maps.LatLng(newNeLat, newNeLng),
    )

    return newBounds
  }

  public static getBoundsZoomLevel = (
    bounds: google.maps.LatLngBounds,
    mapDim: {width: number; height: number},
  ) => {
    var WORLD_DIM = {height: 256, width: 256}
    var ZOOM_MAX = 21

    function latRad(lat: number) {
      var sin = Math.sin((lat * Math.PI) / 180)
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    function zoom(mapPx: number, worldPx: number, fraction: number) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    var ne = bounds.getNorthEast()
    var sw = bounds.getSouthWest()

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

    var lngDiff = ne.lng() - sw.lng()
    var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

    return Math.min(latZoom, lngZoom, ZOOM_MAX)
  }

  public static drawPoly = (map: google.maps.Map) => {
    let visibleRegion: google.maps.LatLngBounds | undefined = map.getBounds()
    if (!visibleRegion) {
      return false
    }
    let northEast = visibleRegion.getNorthEast()
    let southWest = visibleRegion.getSouthWest()
    let northWest = new google.maps.LatLng(northEast.lat(), southWest.lng())
    let southEast = new google.maps.LatLng(southWest.lat(), northEast.lng())

    const coords = [
      {lat: northEast.lat(), lng: northEast.lng()},
      {lat: southEast.lat(), lng: southEast.lng()},
      {lat: southWest.lat(), lng: southWest.lng()},
      {lat: northWest.lat(), lng: northWest.lng()},
    ]

    const adjustedCoords = this.adjustBoundary(coords)

    const rectangle = new google.maps.Polygon({
      paths: adjustedCoords,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    })
    rectangle.setMap(map)
    return rectangle
  }

  public static adjustBoundary = (coords: {lat: number; lng: number}[]) => {
    const defaultLatPercentage = 0.01
    const defaultLngPercentage = 0.01
    const northPercentage = 0.1

    let northEast = new google.maps.LatLng(coords[0].lat, coords[0].lng)
    let southEast = new google.maps.LatLng(coords[1].lat, coords[1].lng)
    let southWest = new google.maps.LatLng(coords[2].lat, coords[2].lng)
    let northWest = new google.maps.LatLng(coords[3].lat, coords[3].lng)

    let defaultLatAdjustment =
      (northEast.lat() - southWest.lat()) * defaultLatPercentage
    let defaultLngAdjustment =
      (northEast.lng() - southWest.lng()) * defaultLngPercentage

    let northLatAdjustment =
      (northEast.lat() - southWest.lat()) * northPercentage
    // let northLngAdjustment =
    //   (northEast.lng() - southWest.lng()) * northPercentage

    let adjustedNorthEast = new google.maps.LatLng(
      northEast.lat() - northLatAdjustment,
      northEast.lng() - defaultLngAdjustment,
    )
    let adjustedSouthWest = new google.maps.LatLng(
      southWest.lat() + defaultLatAdjustment,
      southWest.lng() + defaultLngAdjustment,
    )
    let adjustedSouthEast = new google.maps.LatLng(
      southEast.lat() + defaultLatAdjustment,
      southEast.lng() - defaultLngAdjustment,
    )
    let adjustedNorthWest = new google.maps.LatLng(
      northWest.lat() - northLatAdjustment,
      northWest.lng() + defaultLngAdjustment,
    )

    return [
      {lat: adjustedNorthEast.lat(), lng: adjustedNorthEast.lng()},
      {lat: adjustedSouthEast.lat(), lng: adjustedSouthEast.lng()},
      {lat: adjustedSouthWest.lat(), lng: adjustedSouthWest.lng()},
      {lat: adjustedNorthWest.lat(), lng: adjustedNorthWest.lng()},
    ]
  }

  // BELOW IS FOR DEBUGGING ONLY

  public static drawNewBounds = (map: google.maps.Map) => {
    let visibleRegion: google.maps.LatLngBounds | undefined = map.getBounds()
    if (!visibleRegion) {
      return false
    }
    let northEast = new google.maps.LatLng(51.16588194411646, 4.159057060746236)
    let southWest = new google.maps.LatLng(
      50.88949128066928,
      3.3295892873087363,
    )
    let northWest = new google.maps.LatLng(
      51.16588194411646,
      3.3295892873087363,
    )
    let southEast = new google.maps.LatLng(50.88949128066928, 4.159057060746236)

    const coords = [
      {lat: northEast.lat(), lng: northEast.lng()},
      {lat: southEast.lat(), lng: southEast.lng()},
      {lat: southWest.lat(), lng: southWest.lng()},
      {lat: northWest.lat(), lng: northWest.lng()},
    ]

    const rectangle = new google.maps.Polygon({
      paths: coords,
      strokeColor: '#0000FF',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#0000FF',
      fillOpacity: 1,
    })
    rectangle.setMap(map)
  }

  public static drawOldBounds = (map: google.maps.Map) => {
    let visibleRegion: google.maps.LatLngBounds | undefined = map.getBounds()
    if (!visibleRegion) {
      return false
    }
    let northEast = new google.maps.LatLng(51.16588194411646, 4.297416130570455)
    let southWest = new google.maps.LatLng(
      50.88949128066928,
      3.1912302174845175,
    )
    let northWest = new google.maps.LatLng(
      51.16588194411646,
      3.1912302174845175,
    )
    let southEast = new google.maps.LatLng(50.88949128066928, 4.297416130570455)

    const coords = [
      {lat: northEast.lat(), lng: northEast.lng()},
      {lat: southEast.lat(), lng: southEast.lng()},
      {lat: southWest.lat(), lng: southWest.lng()},
      {lat: northWest.lat(), lng: northWest.lng()},
    ]

    const rectangle = new google.maps.Polygon({
      paths: coords,
      strokeColor: '#00FF00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#00FF00',
      fillOpacity: 1,
    })
    rectangle.setMap(map)
  }

  public static drawFilteredOutBounds = (map: google.maps.Map) => {
    let visibleRegion: google.maps.LatLngBounds | undefined = map.getBounds()
    if (!visibleRegion) {
      return false
    }
    let northEast = new google.maps.LatLng(
      51.165881944116485,
      3.3302759328165488,
    )
    let southWest = new google.maps.LatLng(50.88949128066934, 3.19191686299233)
    let northWest = new google.maps.LatLng(51.165881944116485, 3.19191686299233)
    let southEast = new google.maps.LatLng(
      50.88949128066934,
      3.3302759328165488,
    )

    const coords = [
      {lat: northEast.lat(), lng: northEast.lng()},
      {lat: southEast.lat(), lng: southEast.lng()},
      {lat: southWest.lat(), lng: southWest.lng()},
      {lat: northWest.lat(), lng: northWest.lng()},
    ]

    const rectangle = new google.maps.Polygon({
      paths: coords,
      strokeColor: '#FFFF00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FFFF00',
      fillOpacity: 1,
    })
    rectangle.setMap(map)
  }
}

export default MapUtils
