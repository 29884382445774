import Image from 'next/image'
import React from 'react'

interface ServicePointGeolocationButtonProps {
  isActive: boolean
  onClicked: () => void
}

function ServicePointGeolocationButton({
  isActive,
  onClicked,
}: ServicePointGeolocationButtonProps) {
  return (
    <div className="rounded-dats">
      <button onClick={onClicked}>
        <Image
          src={
            isActive
              ? '/images/icon-location-active.svg'
              : '/images/icon-location-inactive.svg'
          }
          alt="Center to user location"
          width={56}
          height={56}
          unoptimized={true}
        />
      </button>
    </div>
  )
}

export default ServicePointGeolocationButton
